import React from 'react';
import styled from 'styled-components';

const Wrap = styled.div`
	display: none;
	position: relative;
	@media only screen and (min-width: 1088px) {
		display: flex;
	}
`;

const Primary = styled.div`
	width: 50%;
	height: 100%;
	background-color: tomato;
	z-index: 10;
	position: relative;
	img {
		width: 110%;
		display: block;
	}
	h3 {
		color: white;
		font-size: 2.5rem;
		line-height: 1.2;
	}
	a {
		color: white;
		text-decoration: none;
		font-size: 1rem;
		font-weight: 200;
	}
`;

const Overlay = styled.div`
	padding: 0 4rem;
	display: flex;
	align-items: center;
	position: absolute;
	z-index: 15;
	top: 0;
	left: 0;
	height: 100%;
`;

const Secondary = styled.div`
	width: 50%;
	display: flex;
	flex-direction: column;
	align-items: center;
	z-index: 0;
	background-color: #ddd;
`;

const SecondaryItem = styled.div`
	width: 100%;
	height: 50%;
	overflow: hidden;
	flex-grow: 1;
	background: linear-gradient(to right top, #f2f2f2, #aaa);
	display: flex;
	align-items: center;
	div {
		display: inline-block;
	}
	&:last-child {
		background: linear-gradient(to right top, #aaa, #f2f2f2);
	}
	padding: 0 4rem 0 8rem;
	h3 {
		font-size: 1.5rem;
		line-height: 1.2;
	}
	a {
		color: black;
		text-decoration: none;
		font-size: 1rem;
		font-weight: 200;
	}
`;

const articles = [
	{
		title: 'Alternative Debt Solutions for Your Company',
		image: '/img/SpergelCorporate_FeaturedArticle.png',
		link: '/insights/alternative-debt-solutions-for-your-company/',
	},
	{
		title: '5 Steps to Getting Your Small Business Debt Free',
		image: '/img/SpergelCorporate_FeaturedArticle.png',
		link: '/insights/5-steps-to-getting-your-small-business-debt-free/',
	},
	{
		title: 'Lending to Your Company: Protecting Your Shareholder Loan, Part I',
		image: '/img/SpergelCorporate_FeaturedArticle.png',
		link:
			'/insights/lending-to-your-company-protecting-your-shareholder-loan-part-i/',
	},
];

export default () => {
	return (
		<Wrap>
			<Primary>
				<img src={articles[0].image} alt="" />
				<Overlay>
					<div>
						<h3>{articles[0].title}</h3>
						<a href={articles[0].link}>READ MORE →</a>
					</div>
				</Overlay>
			</Primary>
			<Secondary>
				<SecondaryItem>
					<div>
						<h3>{articles[1].title}</h3>
						<a href={articles[1].link}>READ MORE →</a>
					</div>
				</SecondaryItem>
				<SecondaryItem>
					<div>
						<h3>{articles[2].title}</h3>
						<a href={articles[2].link}>READ MORE →</a>
					</div>
				</SecondaryItem>
			</Secondary>
		</Wrap>
	);
};
