import React from 'react';
import styled from 'styled-components';

import common from '../styles/common';

//import Container from 'react-bulma-components/lib/components/container';
//import Columns from 'react-bulma-components/lib/components/columns';

import ServiceIndustryTile from './ServiceIndustryTile';
import Button from './Button';

// Split array in half.
// Larger half will always be first.
// Returns array of halves.
function splitArrayInHalf(arr) {
	const half = Math.ceil(arr.length / 2);
	const firstHalf = arr.slice(0, half);
	const secondHalf = arr.slice(half);
	return [firstHalf, secondHalf];
}

const Inner = styled.div`
	@media ${common.devices.desktop} {
		margin-top: ${(p) => p.offset};
	}
`;

export default ({ industries, bg, home }) => {
	const [first, second] = splitArrayInHalf(industries);
	return (
		<Wrap bg={bg}>
			<div className="container">
				{home && (
					<ContentWrap>
						<h3>
							We’ve worked across almost every industry over the last 30 years.
						</h3>
						<p>
							Our consulting services and restructuring experience have provided
							both business owners and lenders with practical and efficient debt
							help solutions across various industries, including agriculture,
							construction, distribution, healthcare, logistics, technology,
							manufacturing, real estate, retail, film and media and more.
						</p>
						<p>
							If you are a business owner, give us a call for a no-commitment
							conversation with a licensed insolvency trustee about your current
							financial troubles, which can include Canada Revenue Agency
							problems, issues with your bank or lender, or anything else that
							is keeping you from focusing on running your business. Let us help
							you navigate through your current financial issues with your
							lender by leveraging our vast network of relationships within the
							lending community.
						</p>
					</ContentWrap>
				)}
				{!home && (
					<div style={{ textAlign: 'center', marginBottom: '2rem' }}>
						<h2>Unmatched Industry Expertise:</h2>
					</div>
				)}
				<div className="columns is-gapless">
					<div className="column">
						<Inner>
							{first.map((industry, index) => (
								<ServiceIndustryTile
									title={industry.name}
									image={industry.image}
									slug={industry.slug}
									key={index}
								/>
							))}
						</Inner>
					</div>
					<div className="column">
						<Inner offset={'4rem'}>
							{second.map((industry, index) => (
								<ServiceIndustryTile
									title={industry.name}
									image={industry.image}
									slug={industry.slug}
									key={index}
								/>
							))}
						</Inner>
					</div>
				</div>
				<BottomWrap>
					<p>Don't see your industry listed?</p>
					<a href="team">
						<Button>TALK TO A TRUSTEE</Button>
					</a>
				</BottomWrap>
			</div>
		</Wrap>
	);
};

const Wrap = styled.div`
	padding: 2rem 0;
	background-color: ${(p) => (p.bg ? p.bg : '')};
`;

const ContentWrap = styled.div`
	margin-bottom: 4rem;
	h2 {
		font-size: 1rem;
		font-weight: 700;
	}
	h3 {
		font-size: 2rem;
		font-weight: 400;
	}
	padding: 0 2rem;
	@media only screen and (min-width: 1088px) {
		padding: 0;
	}
`;

const BottomWrap = styled.div`
	text-align: center;
	padding: 2rem 0;
	p {
		font-weight: 700;
	}
`;
