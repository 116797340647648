import React from 'react';
import styled from 'styled-components';

import common from '../styles/common';

import Button from './Button';
import TeamCarousel from './TeamCarousel';

const Wrap = styled.div`
	display: flex;
	flex-direction: column;
	height: auto;
	@media only screen and (min-width: 950px) {
		height: 500px;
		flex-direction: row;
	}
`;
const Content = styled.div`
	padding: 4rem 2rem;
	@media only screen and (min-width: 1088px) {
		padding: 2rem 4rem;
	}
	@media only screen and (min-width: 1400px) {
		padding: 4rem 8rem;
	}
	h2 {
		font-size: 1.5rem;
		margin-top: 0;
		@media only screen and (min-width: 1200px) {
			font-size: 2rem;
		}
		font-weight: 200;
	}
	a {
		color: ${common.slate};
		font-weight: 700;
		text-decoration: none;
		border-bottom: 1px solid ${common.lightGrey};
		padding-bottom: 0.125rem;
	}
	.button-tray {
		a {
			border: none;
		}
	}
	p {
		margin-bottom: 2rem;
	}
	button:first-of-type {
		margin-right: 1rem;
	}
`;

export default ({ teamMembers }) => {
	return (
		<Wrap>
			<Content>
				<h2>
					Providing business owners with{' '}
					<a href="/services/incorporated-business-debt-solutions/">
						debt solutions
					</a>
					, <a href="/services/restructuring-business/">restructuring</a> and{' '}
					<a href="/services/business-advisory-business-owners/">
						consulting services
					</a>{' '}
					for over 30 years.
				</h2>
				<p>
					Spergel's experienced and hands-on professionals have helped hundreds
					of business owners find solutions to their debt problems. If you're a
					business owner who is unsure how to manage your debt, a Spergel
					licensed insolvency trustee can help with a solution that is both
					practical and affordable.
				</p>
				<div className="button-tray">
					<a href="/services">
						<Button>SERVICES</Button>
					</a>
					<a href="/industries">
						<Button>INDUSTRIES</Button>
					</a>
				</div>
			</Content>
			<TeamCarousel teamMembers={teamMembers} />
		</Wrap>
	);
};
