import React from 'react';
import styled from 'styled-components';

import Button from './Button';

//import Container from 'react-bulma-components/lib/components/container';

export default () => {
	return (
		<Wrap>
			<div className="container">
				<h1>Get back to running your business.</h1>
				<h2>Let us solve your financial problems.</h2>
				<div className="button-tray">
					<a href="/services">
						<Button>OUR SERVICES</Button>
					</a>
					<a href="/industries">
						<Button>INDUSTRY EXPERIENCE</Button>
					</a>
					<a href="/contact">
						<Button>CONTACT US</Button>
					</a>
				</div>
			</div>
		</Wrap>
	);
};

const Wrap = styled.div`
	padding: 2rem;
	height: 180vw;
	text-align: center;
	@media only screen and (min-width: 768px) {
		text-align: left;
		height: 500px;
	}
	display: flex;

	background-image: url('/img/SpergelCorporate_Home_Masthead_Mobile.jpg');
	padding: 2rem 0;
	align-items: flex-end;
	@media only screen and (min-width: 768px) {
		align-items: center;
		background-image: url('/img/SpergelCorporate_Home_Masthead.jpg');
		padding: 0;
	}
	background-position: center right;
	background-size: cover;
	.button-tray {
		display: flex;
		flex-direction: column;
		@media only screen and (min-width: 768px) {
			flex-direction: row;
		}
	}
	button {
		margin-right: 1rem;
		margin-bottom: 1rem;
	}
	h1 {
		font-size: 2rem;
		font-weight: 400;
		margin: 0;
		line-height: 1.2;
		@media only screen and (min-width: 768px) {
			font-size: 2.5rem;
		}
	}
	h2 {
		font-weight: 400;
		margin-top: 0;
		margin-bottom: 2rem;
		font-size: 1.5rem;
	}
`;
