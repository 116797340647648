import React from 'react';
import styled from 'styled-components';

const Wrap = styled.div`
	padding: 0.5rem;
`;

const Inner = styled.div`
	background-image: url(${(p) => p.image});
	background-size: cover;
	background-position: center center;
	height: 160px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1rem;

	a {
		text-decoration: none;
	}
`;

const Title = styled.h3`
	color: white;
	font-weight: 700;
	text-align: center;
	font-size: 2rem;
`;

export default ({ title, image, slug }) => {
	return (
		<Wrap>
			<Inner image={image}>
				<a href={slug}>
					<Title>{title}</Title>
				</a>
			</Inner>
		</Wrap>
	);
};
