import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

export default () => (
	<Wrap>
		<h3>
			We are open and here for <strong>you</strong>.
		</h3>
		<p>
			In consideration of COVID-19, all consultations will now be conducted by{' '}
			<Link to="contact">phone</Link> or{' '}
			<Link to="/contact">video conferencing</Link>.
		</p>
	</Wrap>
);

const Wrap = styled.nav`
	padding: 2rem;
	text-align: center;
	width: 100%;
	background-color: #617897;
	color: white;
	h3 {
		margin-top: 0;
		font-size: 2rem;
		font-weight: 300;
	}
	a {
		color: white;
	}
`;
