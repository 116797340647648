import React, { useState } from 'react';
import { navigate, graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import Select from 'react-select';

//import Container from 'react-bulma-components/lib/components/container';
//import Columns from 'react-bulma-components/lib/components/columns';

import common from '../styles/common';
import Button from './Button';
import ArrowLink from './ArrowLink';

const Wrap = styled.div`
	padding: 4rem 2rem;
	@media only screen and (min-width: 1088px) {
		padding: 4rem 0;
	}
	background-image: url('/img/SpergelCorporate_Home_Search.jpg');
	background-size: cover;
	background-position: center center;
	text-align: center;
	h2 {
		text-align: center;
		margin-bottom: 4rem;
	}
	a {
		text-decoration: none;
		color: ${common.spergelRed};
		font-size: 1rem;
		font-weight: 700;
	}
`;

// react-select style API.
const customStyles = {
	control: (provided) => ({
		...provided,
		width: '100%',
		fontSize: '1.5rem',
		backgroundColor: 'transparent',
		borderRadius: 0,
		border: 'none',
		borderBottom: `2px solid ${common.lightGrey}`,
		paddingBottom: '0.5rem',
	}),
	dropdownIndicator: (provided) => ({
		...provided,
		opacity: 1,
		color: 'black',
	}),
};

export default () => {
	const data = useStaticQuery(graphql`
		{
			industries: allIndustriesJson(sort: { fields: [name] }) {
				edges {
					node {
						name
					}
				}
			}
			services: allServicesJson(sort: { fields: [name] }) {
				edges {
					node {
						name
					}
				}
			}
			team: allTeamJson(sort: { fields: [name] }) {
				edges {
					node {
						name
						fields {
							slug
						}
					}
				}
			}
		}
	`);

	const industriesList = data.industries.edges.map(({ node }) => ({
		value: node.name,
		label: node.name,
	}));

	const servicesList = data.services.edges.map(({ node }) => ({
		value: node.name,
		label: node.name,
	}));

	const teamList = data.team.edges.map(({ node }) => ({
		value: node.fields.slug,
		label: node.name,
	}));

	const [service, setService] = useState();
	const [industry, setIndustry] = useState();
	const [trustee, setTrustee] = useState();

	return (
		<Wrap>
			<h2>We have the expert you're looking for:</h2>
			<div className="container">
				<div className="columns" style={{ marginBottom: '4rem' }}>
					<div className="column">
						<Select
							isClearable
							options={servicesList}
							styles={customStyles}
							isSearchable={false}
							placeholder={'Service'}
							onChange={(e) => {
								setService(e ? e.value : null);
							}}
						/>
					</div>
					<div className="column">
						<Select
							isClearable
							options={industriesList.filter(
								(industry) => industry !== 'Others'
							)}
							styles={customStyles}
							isSearchable={false}
							placeholder={'Industry'}
							onChange={(e) => {
								setIndustry(e ? e.value : null);
							}}
						/>
					</div>
					<div className="column">
						<Select
							isClearable
							options={teamList}
							styles={customStyles}
							isSearchable={false}
							placeholder={'Trustee'}
							onChange={(e) => {
								setTrustee(e ? e.value : null);
							}}
						/>
					</div>
				</div>

				<Button
					size="large"
					style={{ marginBottom: '2rem' }}
					onClick={() => {
						if (trustee) {
							navigate(trustee);
						} else {
							navigate('/team', {
								state: {
									service: service,
									industry: industry,
									trustee: trustee,
								},
							});
						}
					}}
				>
					SEARCH
				</Button>
				<div>
					<ArrowLink href="/team">VIEW OUR FULL TEAM</ArrowLink>
				</div>
			</div>
		</Wrap>
	);
};
