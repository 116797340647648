import React from 'react';
import Layout from '../components/Layout';
import HomeTemplate from '../templates/HomeTemplate';
import { graphql } from 'gatsby';

const dummyData = {
	masthead: {
		title: 'Technology',
		image: '/img/SpergelCorporate_Services_Masthead.jpg',
	},
	side_left: {
		image: '/img/SpergelCorporate_About_OurMission_Img.jpg',
		image_alt: 'Hands',
		is_left: true,
		side_content:
			'## Our Mission\n\nLorem, ipsum dolor sit amet consectetur adipisicing elit. Delectus quis voluptatem, aut quaerat repellat, commodi dignissimos.',
		main_content:
			'## Our Story\n\nLorem, ipsum dolor sit amet consectetur adipisicing elit. Delectus quis voluptatem, aut quaerat repellat, commodi dignissimos voluptates quidem doloremque recusandae provident exercitationem fugit illo neque? Aliquid eligendi non velit vel?',
	},
	side_right: {
		image: '/img/SpergelCorporate_ServiceDetail_Insolvency.jpg',
		image_alt: 'Hands',
		is_left: false,
		main_content:
			'## Our Story\n\nLorem, ipsum dolor sit amet consectetur adipisicing elit. Delectus quis voluptatem, aut quaerat repellat, commodi dignissimos voluptates quidem doloremque recusandae provident exercitationem fugit illo neque? Aliquid eligendi non velit vel?',
	},
	industry_tiles: {
		industries: [
			{
				name: 'Agriculture',
				image: '/img/SpergelCorporate_Home_Agriculture.jpg',
				slug: '/industries/agriculture',
			},
			{
				name: 'Distribution',
				image: '/img/SpergelCorporate_Home_Distribution.jpg',
				slug: '/industries/distribution',
			},
			{
				name: 'Technology',
				image: '/img/SpergelCorporate_Home_Technology.jpg',
				slug: '/industries/technology',
			},
			{
				name: 'Real Estate',
				image: '/img/SpergelCorporate_Home_RealEstate.jpg',
				slug: '/industries/real-estate',
			},
			{
				name: 'Construction',
				image: '/img/SpergelCorporate_Home_Construction.jpg',
				slug: '/industries/construction',
			},
			{
				name: 'Healthcare',
				image: '/img/SpergelCorporate_Home_Healthcare.jpg',
				slug: '/industries/healthcare',
			},
			{
				name: 'Manufacturing',
				image: '/img/SpergelCorporate_Home_Manufacturing.jpg',
				slug: '/industries/manufacturing',
			},
			{
				name: 'Retail',
				image: '/img/SpergelCorporate_Home_Retail.jpg',
				slug: '/industries/retail',
			},
		],
	},
};

const HomePage = ({ data }) => {
	const teamMembers = data.allTeamJson.edges.map((edge) => edge.node);
	return (
		<Layout>
			<HomeTemplate teamMembers={teamMembers} data={dummyData} />
		</Layout>
	);
};

export default HomePage;

export const query = graphql`
	query {
		allTeamJson(sort: { fields: [order] }) {
			edges {
				node {
					name
					title
					side_image
					certifications
					industries {
						industry
					}
				}
			}
		}
	}
`;
