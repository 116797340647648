import React from 'react';

import { PageWrap } from '../styles/common';
import IndustryTiles from '../components/IndustryTiles';
import CtaBar from '../components/CtaBar';
import ExpertFinder from '../components/ExpertFinder';
import BlogFeatures from '../components/BlogFeatures';
import ExpertsFeature from '../components/ExpertsFeature';
import Hero from '../components/Hero';
import Notification from '../components/Notification';

export default ({ data, teamMembers }) => {
	return (
		<PageWrap>
			<Hero />
			<Notification />
			<ExpertsFeature teamMembers={teamMembers} />
			<ExpertFinder />
			<IndustryTiles home={true} industries={data.industry_tiles.industries} />
			<CtaBar>
				<h3>
					<a href="/contact">Contact us today</a> for a free consultation.
				</h3>
			</CtaBar>
			<BlogFeatures />
		</PageWrap>
	);
};
