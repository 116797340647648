import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import common from '../styles/common';

const Wrap = styled.div`
	position: relative;
	height: 100%;
	flex-basis: 100vw;
	@media only screen and (min-width: 950px) {
		flex-basis: 500px;
	}
`;

const Inner = styled.div`
	overflow: hidden;
	width: 100vw;
	@media only screen and (min-width: 950px) {
		width: 500px;
	}
	position: relative;
`;

const ClipOverlay = styled.img`
	position: absolute;
	top: 0;
	height: 100%;
	width: auto;
	left: 0;
	display: none;
	@media only screen and (min-width: 950px) {
		display: block;
	}
`;

const Image = styled.img`
	width: 100vw;
	@media only screen and (min-width: 950px) {
		width: 500px;
	}
	height: auto;
	display: block;
`;

const SlideIn = styled.img`
	position: absolute;
	top: 0;
	height: auto;
	width: 100vw;
	@media only screen and (min-width: 950px) {
		width: 500px;
	}
	left: 0;
	display: block;
	transition: ${(p) =>
		p.animating ? 'transform ease-out 300ms' : 'transform 0'};
	transform: ${(p) => {
		if (p.animating === true) {
			return 'translateX(0)';
		} else {
			return p.isFromLeft ? 'translateX(-100vw)' : 'translateX(100vw)';
		}
	}};
`;

const Panel = styled.div`
	background-color: ${common.blue};
	color: white;
	height: 6rem;
	position: absolute;
	width: 100%;
	bottom: 3rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	button {
		margin: 0;
		padding: 1rem;
		border: none;
		cursor: pointer;
		background-color: transparent;
		img {
			transition: transform ease-out 200ms;
			padding: 0rem;
			width: 0.75rem;
		}
		&:hover img {
			transform: scale(1.2);
		}
		outline: none;
	}
	div {
		width: 100%;
		padding-left: 1rem;
		h4 {
			text-align: left;
			font-size: 1.25rem;
			margin: 0;
			line-height: 1rem;
			a {
				color: white;
				text-decoration: none;
				&:hover {
					text-decoration: underline;
				}
			}
			span {
				font-weight: 300;
				font-size: 1rem;
				a {
					color: white;
					text-decoration: underline;
				}
			}
		}
	}
`;

const getNextIndex = (current, array) => {
	if (current + 1 < array.length) {
		return current + 1;
	} else {
		return 0;
	}
};

const getPrevIndex = (current, array) => {
	if (current > 0) {
		return current - 1;
	} else {
		return array.length - 1;
	}
};

export default ({ teamMembers }) => {
	const [index, setIndex] = useState(0);
	const [animating, setAnimating] = useState(false);
	const [isFromLeft, setIsFromLeft] = useState(true);
	const [currentImage, setCurrentImage] = useState(
		teamMembers[index].side_image
	);
	const [nextImage, setNextImage] = useState(teamMembers[index].side_image);
	const [content, setContent] = useState({
		name: teamMembers[index].name,
		title: teamMembers[index].title,
		certifications: teamMembers[index].certifications,
		industries: teamMembers[index].industries,
	});

	function useInterval(callback, delay) {
		const savedCallback = useRef();

		// Remember the latest callback.
		useEffect(() => {
			savedCallback.current = callback;
		}, [callback]);

		// Set up the interval.
		useEffect(() => {
			function tick() {
				savedCallback.current();
			}
			if (delay !== null) {
				let id = setInterval(tick, delay);
				return () => clearInterval(id);
			}
		}, [delay]);
	}

	useInterval(() => {
		slide(false);
	}, 4000);

	const slide = (isFromLeft) => {
		if (!animating) {
			const nextIndex = isFromLeft
				? getPrevIndex(index, teamMembers)
				: getNextIndex(index, teamMembers);
			setIsFromLeft(isFromLeft);
			setNextImage(teamMembers[nextIndex].side_image);
			setTimeout(() => {
				setContent({
					name: teamMembers[nextIndex].name,
					title: teamMembers[nextIndex].title,
					certifications: teamMembers[nextIndex].certifications,
					industries: teamMembers[nextIndex].industries,
				});
				setAnimating(true);
				setTimeout(() => {
					setCurrentImage(teamMembers[nextIndex].side_image);
					setTimeout(() => {
						setAnimating(false);
						setIndex(nextIndex);
					}, 100);
				}, 1000);
			}, 25);
		}
	};

	return (
		<>
			{teamMembers.map(({ side_image }) => (
				<img src={side_image} alt="preload" style={{ display: 'none' }} />
			))}
			<Wrap>
				<Inner>
					<Image src={currentImage} alt="" />
					<SlideIn
						src={nextImage}
						animating={animating}
						isFromLeft={isFromLeft}
					/>
					<ClipOverlay src="/img/left-clip.svg" alt="" />
					<Panel>
						<button onClick={() => slide(true)}>
							<img src="/img/left-triangle.svg" alt="left" />
						</button>
						<div>
							<h4 style={{ marginBottom: '0.5rem' }}>
								<a href={`/team/${slugize(content.name)}`}>{content.name}</a>{' '}
								<span>
									{content.name === 'Glen Dalzell' ? (
										<>
											President,{' '}
											<a
												target="_blank"
												rel="noopener noreferrer"
												href="https://www.spergelcorporatefinance.ca"
											>
												Spergel Corporate Finance
											</a>
										</>
									) : (
										<>{content.title}</>
									)}
								</span>
							</h4>
							<h4>
								<span>{content.certifications}</span>
							</h4>
						</div>
						<button onClick={() => slide(false)}>
							<img src="/img/right-triangle.svg" alt="right" />
						</button>
					</Panel>
				</Inner>
			</Wrap>
		</>
	);
};

const slugize = (input) => {
	const lower = input.toLowerCase();
	const withoutPeriod = lower.replace(/\./g, '');
	return withoutPeriod.replace(/ /g, '-');
};
